var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"title":"详情","width":"800px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('template',{slot:"footer"},[_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.record.status_slug === 'invoiced'),expression:"record.status_slug === 'invoiced'"}],attrs:{"type":"primary"},on:{"click":_vm.closeModal}},[_vm._v("关闭")]),_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.record.status_slug === 'un_invoiced'),expression:"record.status_slug === 'un_invoiced'"}],on:{"click":_vm.closeModal}},[_vm._v("取消")]),_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.record.status_slug === 'un_invoiced'),expression:"record.status_slug === 'un_invoiced'"}],attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1),_c('basic-info',{attrs:{"id":_vm.record.id}}),_c('a-divider'),_c('a-form',{staticClass:"custom-compact-form",staticStyle:{"width":"700px"},attrs:{"form":_vm.form,"label-col":{ span: 3 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"开票凭证"}},[(_vm.record.status_slug === 'un_invoiced')?_c('div',[_c('span',{staticClass:"tip-text"},[_vm._v("请上传电子发票文件,10MB以内 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
              rules:[
                {validator: _vm.checkAttachments},
                { required:true, message: '请上传凭证' }
              ]
            }]),expression:"['attachments',{\n              rules:[\n                {validator: checkAttachments},\n                { required:true, message: '请上传凭证' }\n              ]\n            }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":1},on:{"change":_vm.handleUploadImage}})],1):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.record.status_slug === 'invoiced' && _vm.record.images !== null),expression:"record.status_slug === 'invoiced' && record.images !== null"}],staticClass:"custom-blue",on:{"click":_vm.showImg}},[_vm._v(_vm._s(_vm.record.images !== null? _vm.record.images[0].response.filename:''))])]),_c('a-form-item',{attrs:{"label":"备注"}},[(_vm.record.status_slug === 'un_invoiced')?_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}]}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.record.status_slug === 'invoiced'),expression:"record.status_slug === 'invoiced'"}]},[_vm._v(" "+_vm._s(_vm.record.remark)+" ")])],1)],1),_c('preview-image',{attrs:{"visible":_vm.previewVisible,"images":_vm.previewImages},on:{"update:visible":function($event){_vm.previewVisible=$event},"update:images":function($event){_vm.previewImages=$event}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }