<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-descriptions :column="1" title="用户信息" class="custom-descriptions custom-descriptions-max-6">
          <a-descriptions-item label="用户姓名">
            {{ data.user_name }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ data.user_phone_number }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="1" title="申请信息" class="custom-descriptions-max-6 custom-descriptions">
          <a-descriptions-item label="申请状态">
            {{ data.status }}
          </a-descriptions-item>
          <a-descriptions-item label="发票形式">
            {{ data.invoice_type }}
          </a-descriptions-item>
          <a-descriptions-item label="发票金额(元)">
            {{ data.amount | formatCurrency }}
          </a-descriptions-item>
          <a-descriptions-item label="申请时间">
            {{ data.created_at }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="1" :title="`关联订单(${orderNos.length}个)`" class="custom-descriptions">
          <p
            v-for="orderNo in orderNos"
            :key="orderNo"
          >
            {{ orderNo }}
          </p>
        </a-descriptions>
      </a-col>
      <a-col :span="12">
        <a-descriptions :column="1" title="抬头信息" class="custom-descriptions">
          <a-descriptions-item label="抬头类型">
            {{ data.header_type_text }}
          </a-descriptions-item>
          <a-descriptions-item label="名称">
            {{ data.name }}
          </a-descriptions-item>
          <a-descriptions-item label="税号" v-if="data.header_type_slug === 'enterprise'">
            {{ data.duty_paragraph }}
          </a-descriptions-item>
          <a-descriptions-item label="公司地址" v-if="data.header_type_slug === 'enterprise'">
            {{ data.address }}
          </a-descriptions-item>
          <a-descriptions-item label="电话号码" v-if="data.header_type_slug === 'enterprise'">
            {{ data.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="开户银行" v-if="data.header_type_slug === 'enterprise'">
            {{ data.bank_of_deposit }}
          </a-descriptions-item>
          <a-descriptions-item label="银行账号" v-if="data.header_type_slug === 'enterprise'">
            {{ data.bank_card_no }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" title="接收方式" class="custom-descriptions">
          <a-descriptions-item label="收件人">
            {{ data.receiver }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ data.phone }}
          </a-descriptions-item>
          <a-descriptions-item label="收件地址">
            {{ data.post_address }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findInvoice } from '@/api/invoice'

export default {
  name: 'InvoiceBasicInfo',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      orderNos: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findInvoice(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          if (res.data.order_nos) {
            this.orderNos = res.data.order_nos
          }
        }
        this.loading = false
      })
    }
  }
}
</script>
