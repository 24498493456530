<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="详情"
      width="800px"
    >
      <template slot="footer">
        <a-button v-show="record.status_slug === 'invoiced'" type="primary" @click="closeModal">关闭</a-button>
        <a-button v-show="record.status_slug === 'un_invoiced'" @click="closeModal">取消</a-button>
        <a-button v-show="record.status_slug === 'un_invoiced'" type="primary" @click="handleSubmit">确定</a-button>
      </template>

      <basic-info :id="record.id" />
      <a-divider />

      <a-form
        class="custom-compact-form"
        :form="form"
        style="width: 700px"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="开票凭证">
          <div v-if="record.status_slug === 'un_invoiced'">
            <span
              class="tip-text"
            >请上传电子发票文件,10MB以内
            </span>
            <upload-image
              :is-add-watermark="isAddWatermark"
              accept=".jpg,.jpeg,.png"
              :max-count="1"
              @change="handleUploadImage"
              v-decorator="['attachments',{
                rules:[
                  {validator: checkAttachments},
                  { required:true, message: '请上传凭证' }
                ]
              }]"
            />
          </div>
          <span v-show="record.status_slug === 'invoiced' && record.images !== null" class="custom-blue" @click="showImg">{{ record.images !== null? record.images[0].response.filename:'' }}</span>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-if="record.status_slug === 'un_invoiced'"
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
          <div v-show="record.status_slug === 'invoiced'">
            {{ record.remark }}
          </div>
        </a-form-item>
      </a-form>

      <!-- 预览图片 -->
      <preview-image
        :visible.sync="previewVisible"
        :images.sync="previewImages"
      />
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import BasicInfo from '@/views/invoices/BasicInfo'
import { newInvoice } from '@/api/invoice'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'InvoiceDetail',
  components: {
    BasicInfo,
    UploadImage,
    PreviewImage
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'invoice_detail' }),
      isAddWatermark: false,
      attachments: [], // 图片
      submitting: false,
      previewVisible: false,
      previewImages: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    },

    showImg() {
      this.previewVisible = true
      this.previewImages = this.record.images
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          newInvoice(this.record.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
